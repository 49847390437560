import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { SETEC_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import ContentSection from '@/components/Portfolio/ContentSection';
import NextProject from '@/components/Portfolio/NextProject';

export default function EDF({ data }) {
  const { t } = useTranslation();

  const tags = [
    'refacto',
    'ux',
    'proto',
    'front',
    'saas',
    'react',
    'storybook',
    'tailwind',
    'js',
    'css',
  ];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.firstScreen} alt={t('devIaScreen1')} />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
      <SoloPicture source={data.secondScreen} alt={t('devIaScreen2')} />
      <ContentSection title={t('paragraph2Title')} content={t('paragraph2')} />

      <NextProject title={t('nextProject')} to={SETEC_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["edf", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    firstScreen: file(relativePath: { eq: "projects/edf/edf1.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    secondScreen: file(relativePath: { eq: "projects/edf/edf2.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
